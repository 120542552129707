

















































































































import { Chrome } from "vue-color";
import { Component, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import ToastedMixin from "@/mixins/ToastedMixin";
import FscCard from "@/components/Card/FscCard.vue";
import CompanyMixin from "@/mixins/CompanyMixin";

const DriveBuzzConfigurationModule = namespace("drive-buzz-config");

@Component({
  components: { FscCard, FormCard, AbortButton, SaveButton, "chrome-picker": Chrome }
})
export default class DriveBuzzConfiguration extends mixins(ToastedMixin, CompanyMixin) {
  public name = "DriveBuzzConfiguration.vue";

  private myDrivingSchoolUrl = false;
  private myDrivingSchoolEnabled = null;
  private bgColor = { hex: "#ffffff" }; // Initial value as object
  private textColor = { hex: "#000000" }; // Initial value as object

  private editMode = false;

  @DriveBuzzConfigurationModule.Action("findAll")
  public driveBuzzConfigFindAll: any;

  @DriveBuzzConfigurationModule.Action("update")
  public driveBuzzConfigsUpdate: any;

  @DriveBuzzConfigurationModule.Getter("getDataList")
  public driveBuzzConfigs: any;

  @DriveBuzzConfigurationModule.Getter("getSuccess")
  public success: any;

  @DriveBuzzConfigurationModule.Getter("getError")
  public error: any;

  @DriveBuzzConfigurationModule.Getter("getIsLoading")
  public isLoading: any;

  public mounted(): void {
    this.driveBuzzConfigFindAll({
      resource: "drive-buzz-config/current"
    });
  }

  toggleEditMode() {
    this.editMode = !this.editMode; // Toggle the edit mode
  }

  @Watch("driveBuzzConfigs")
  private onFurtherEducationConfigsChange(data: any): void {
    if (data) {
      this.myDrivingSchoolUrl = data.myDrivingSchoolUrl;
      this.myDrivingSchoolEnabled = data.myDrivingSchoolEnabled;
      this.bgColor.hex = data.myDrivingSchoolBackgroundColorHex;
      this.textColor.hex = data.myDrivingSchoolTextColorHex;
    }
  }

  private async onSubmit(): Promise<void> {
    if (this.hasNotPermissionWrite) return;
    const data = {
      myDrivingSchoolUrl: this.myDrivingSchoolUrl,
      myDrivingSchoolEnabled: this.myDrivingSchoolEnabled,
      myDrivingSchoolBackgroundColorHex: this.bgColor.hex,
      myDrivingSchoolTextColorHex: this.textColor.hex
    };

    await this.driveBuzzConfigsUpdate({
      id: "current",
      data: data,
      resource: "drive-buzz-config"
    });
  }
}
